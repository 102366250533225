import dynamic from 'next/dynamic';
import Image from 'next/image';
import {shallowEqual, useSelector} from 'react-redux';
import Typography from '@mui/material/Typography';
import {AiFillAndroid, AiFillApple} from 'react-icons/ai';

import {Section, Grid, Box} from '@jobseeker/lotus/lib/foundations';
import {useIsCustomWidth} from '@jobseeker/lotus/lib/hooks';
import InjectSearchOverlay from '@components/SearchInjection';
import {handleTracking} from '@jobseeker/common-core/lib/utils';

import type {DownloadAppType} from '../types';
import type {AppState} from '@jobseeker/common-core/lib/types';

const Button = dynamic(() => import('@jobseeker/lotus/lib/elements/Button'));

const DownloadApp = (props: DownloadAppType) => {
  const {
    appImage,
    dotSvg,
    heading,
    backgroundImg: {large, small},
    theme,
    downloadText,
    isAboveMobile,
    downloadAppButton,
    downloadAppContent,
  } = props || {};

  const isBelowActualMobile = useIsCustomWidth(281);

  const isSearchEnabled = useSelector(
    (state: AppState) => state.app.layout.searchOverlay,
    shallowEqual
  );

  return (
    <Section
      sx={{
        background: {xxs: small, md: large},
      }}
    >
      <InjectSearchOverlay isSearchEnabled={isSearchEnabled} />
      <Grid
        container
        width="90%"
        sx={{
          px: {xxs: 3, sm: 3, md: 5, lg: 8},
          paddingTop: {xxs: theme.spacing(1.25), sm: theme.spacing(2.5)},
          margin: {
            xxs: `${theme.spacing(6.25)} auto 0`,
            md: `${theme.spacing(17.5)} auto 0`,
          },
        }}
      >
        <Grid
          item
          width="100%"
          display="inline-block"
          margin="0 auto"
          paddingBottom={theme.spacing(8.125)}
        >
          <Box
            sx={{
              float: {xxs: '', md: 'right'},
              width: {xxs: '100%', md: '55%'},
              color: 'secondary.main',
              margin: {
                xxs: `${theme.spacing(3.75)} auto`,
                md: `${theme.spacing(2.75)} auto`,
              },
              textAlign: {xxs: 'center', md: 'left'},
              lineHeight: 1,
            }}
          >
            <Typography
              component="h2"
              sx={{
                fontSize: {
                  xxs: theme.typography.pxToRem(24),
                  md: theme.typography.pxToRem(28),
                },
                marginLeft: theme.spacing(0.125),
                fontWeight: {xxs: 700, md: 400},
                '@media (min-width:320px) and (max-width:480px)': {
                  textAlign: 'center',
                },
              }}
            >
              {heading}
            </Typography>
          </Box>

          <Grid
            container={isAboveMobile ? false : true}
            sx={{
              float: {md: 'left'},
              width: {xxs: '100%', md: '40%'},
              margin: `0 auto ${theme.spacing(5)}`,
              justifyContent: 'center',
            }}
          >
            <Image
              src={appImage || ''}
              alt={downloadText || ''}
              width={isAboveMobile ? 290 : 240}
              height={isAboveMobile ? 554 : 440}
              style={{
                maxWidth: isBelowActualMobile
                  ? theme.spacing(26.25)
                  : theme.spacing(36.25),
                display: 'block',
                float: 'right',
                objectFit: 'contain',
              }}
              placeholder="blur"
              blurDataURL={appImage}
            />
          </Grid>
          <Box
            sx={{
              float: {md: 'right'},
              width: {xxs: '100%', md: '55%'},
              margin: 0,
              padding: 0,
              textAlign: 'left',
            }}
          >
            {downloadAppContent.map((content, index) => (
              <Box
                key={`homepage-downloadapp-${index}`}
                margin={
                  isAboveMobile
                    ? `${theme.spacing(3.125)} ${theme.spacing(
                        1.25
                      )} ${theme.spacing(3.125)} ${theme.spacing(0)}`
                    : `${theme.spacing(1.875)} ${theme.spacing(
                        0.625
                      )} ${theme.spacing(1.875)} ${theme.spacing(0)}`
                }
              >
                <Typography
                  component="p"
                  sx={{
                    background: dotSvg,
                    paddingLeft: theme.spacing(2.5),
                    fontSize: isAboveMobile
                      ? theme.typography.pxToRem(15)
                      : theme.typography.pxToRem(18),
                    fontWeight: 700,
                  }}
                >
                  {content.heading}
                </Typography>
                <Typography
                  component="p"
                  sx={{
                    fontSize: isAboveMobile
                      ? theme.typography.pxToRem(15)
                      : theme.typography.pxToRem(18),
                    fontWeight: 400,
                    marginLeft: theme.spacing(2.5),
                  }}
                >
                  {content.text}
                </Typography>
              </Box>
            ))}

            <Typography
              component="p"
              sx={{
                fontSize: isAboveMobile
                  ? theme.typography.pxToRem(15)
                  : theme.typography.pxToRem(18),
                margin: isAboveMobile
                  ? `${theme.spacing(1.875)} ${theme.spacing(0.25)}`
                  : `${theme.spacing(6.875)} ${theme.spacing(
                      0.625
                    )} ${theme.spacing(2.5)} ${theme.spacing(0.625)}`,
              }}
            >
              {downloadText}
            </Typography>
            <Box
              textAlign={isAboveMobile ? 'left' : 'center'}
              justifyContent={{xxs: 'center', sm: 'left'}}
              display={'flex'}
              gap={'10px'}
              flexWrap={'wrap'}
            >
              {downloadAppButton.map((btn, index) => (
                <Button
                  key={`download-home-app-${index}`}
                  variant="outlined"
                  href={btn['link']}
                  target="_blank"
                  onClick={() => {
                    handleTracking(btn['eventTag'], {
                      event_category: 'engagement',
                      label: btn['title'],
                      value: `${index}`,
                    });
                  }}
                  sx={{
                    color: 'text.primary',
                    borderColor: 'secondary.main',
                    borderRadius: 8,
                    fontWeight: isAboveMobile ? 'bold' : 400,
                    fontSize: isAboveMobile
                      ? theme.typography.pxToRem(20)
                      : theme.typography.pxToRem(18),
                    // marginRight: isAboveMobile
                    //   ? theme.spacing(1.25)
                    //   : theme.spacing(1.875),
                    padding: isAboveMobile
                      ? `${theme.spacing(0.625)} ${theme.spacing(3.75)}`
                      : `${theme.spacing(0.25)} ${theme.spacing(1.5)}`,
                    ':hover': {
                      backgroundColor: 'primary.main',
                      color: 'primary.contrastText',
                    },
                    // marginTop: isBelowActualMobile ? '10px' : 0,
                  }}
                  startIcon={
                    btn['title'] === 'Android' ? (
                      <AiFillAndroid />
                    ) : (
                      <AiFillApple />
                    )
                  }
                >
                  {' '}
                  {btn['title']}
                </Button>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Section>
  );
};
export default DownloadApp;
